<template>
  <v-app>
    <div v-if="this.signedIn" class="viewWordsContent">

      <div id="nav">
        <router-link id="backToAdmin" to="/admin">&larr; back to Admin</router-link> |
        <router-link to="/nouns">Nouns</router-link> |
        <router-link to="/sentencesnouns">Sentences &mdash; Nouns</router-link> |
        <router-link to="/adjectives">Adjectives</router-link> |
        <router-link to="/sentencesadjectives">Sentences &mdash; Adjectives</router-link>
      </div>

      <v-expansion-panels>
        <v-expansion-panel style="margin: 10px 0">
          <v-expansion-panel-header style="border: 5px solid #ffbe0b !important;">
            <h3>Instructions</h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-2">
            <div style="text-align:center;margin:15px 0 10px 0; padding:20px;border: 2px solid #343a40; border-radius:20px;">
              <p style="">'English' field must contain a single '_' character where the missing adjective fits, and it must make grammatical sense in English.</p>
              <hr style="border: 1px solid rgba(0,0,0,0.5);width:30%;margin:auto auto 10px auto">
              <p style="">'Russian' field must contain a single '_' character where the missing adjective fits, and it must make grammatical sense in English.</p>
              <hr style="border: 1px solid rgba(0,0,0,0.5);width:30%;margin:auto auto 10px auto">
              <p style="">The 'Adjectives' field contains English adjectives that fit both the translation and the Russian parts.</p>
              <hr style="border: 1px solid rgba(0,0,0,0.5);width:30%;margin:auto auto 10px auto">
              <p style="margin:0;">'Adjectives' must be separated by a ',' comma, and not contain any spaces.</p>
              <p style="margin:0;">E.g. If the 'English' field is "I see blue _", "tree" is NOT a valid adjective, as it doesn't make grammatical sense in English, only Russian.</p>
              <p style="margin:0;">In such instances, simply create a new sentence with the 'English' field, "I see a blue _". The Russian parts can be the same, this is fine.</p>

            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <h1>Add Sentences (for adjectives)</h1>

        <v-card style="border-color: #fff;box-shadow: none; text-align:center">
          <v-row style="margin:0;padding:0">
            <v-col cols="2.5">
              <v-chip label color="#8ecae6">English</v-chip>
            </v-col>
            <v-col cols="2.5">
              <v-chip label color="#8ecae6">Russian</v-chip>
            </v-col>
            <v-col cols="1">
              <v-chip label color="#8ecae6">Case Required</v-chip>
            </v-col>
            <v-col cols="1">
              <v-chip label color="#8ecae6">Type</v-chip>
            </v-col>
            <v-col cols="2.5">
              <v-chip label color="#8ecae6">Adjectives</v-chip>
            </v-col>
            <v-col cols="1">

            </v-col>
          </v-row>
        </v-card>


          <v-card style="border-color: #fff;box-shadow: none; text-align:center">
            <v-row style="margin:0;padding:0">

              <v-col tile cols="2.5" class="d-flex pa-0 ma-1">
                <v-text-field :disabled="inputsDisabled" hide-details tile outlined autocomplete="off" ref="englishTranslationRef" v-model="sentenceEnglishField" class="ma-0 pa-0 text-english"></v-text-field>
              </v-col>

              <v-col tile cols="2.5" class="d-flex pa-0 ma-1">
                <v-text-field :disabled="inputsDisabled" hide-details tile outlined autocomplete="off" v-model="sentenceRussianField" class="ma-0 pa-0"></v-text-field>
              </v-col>

              <v-col tile cols="1" class="d-flex pa-0 ma-1">
                <v-select :disabled="inputsDisabled" hide-details tile outlined autocomplete="off" :items="cases" :class="getBackgroundColour(sentenceCaseRequiredField)" v-model="sentenceCaseRequiredField" class="ma-0 pa-0"></v-select>
              </v-col>

              <v-col tile cols="1" class="d-flex pa-0 ma-1">
                <v-select :disabled="inputsDisabled" hide-details tile outlined autocomplete="off" :items="types" v-model="sentenceTypeField" class="ma-0 pa-0" :class="getColourClass(sentenceTypeField)"></v-select>
              </v-col>

              <v-col tile cols="2.5" class="d-flex pa-0 ma-1">
                <v-text-field :disabled="inputsDisabled" hide-details tile outlined autocomplete="off" v-model="sentenceWordsField" class="ma-0 pa-0"></v-text-field>
              </v-col>

              <v-col cols="1">
                <v-btn :disabled="inputsDisabled" elevation=0 color="green lighten-3" @click="addSentence()">Add</v-btn>
              </v-col>

            </v-row>
          </v-card>









      <v-row class="mt-16">

        <v-col cols="8">
          <h1>View Sentences (for adjectives)</h1>
        </v-col>

        <v-col cols="4">
          <v-text-field
              outlined
              placeholder="Search by adjective"
              autocomplete="off"
              v-model="sentenceSearchBar"
              @input="search()"
              :hint="searchedSentences.length + ' ' + this.searchedResultTag + ' found'"
            ></v-text-field>
        </v-col>

      </v-row>

<!-- Search -->
      <div v-if="searchedSentences.length > 0">

        <v-card style="border-color: #fff;box-shadow: none; text-align:center">
          <v-row style="margin:0;padding:0">
            <v-col cols="2.5">
              <v-chip label color="#8ecae6">English</v-chip>
            </v-col>
            <v-col cols="2.5">
              <v-chip label color="#8ecae6">Russian</v-chip>
            </v-col>
            <v-col cols="1">
              <v-chip label color="#8ecae6">Case Required</v-chip>
            </v-col>
            <v-col cols="1">
              <v-chip label color="#8ecae6">Type</v-chip>
            </v-col>
            <v-col cols="2.5">
              <v-chip label color="#8ecae6">Adjectives</v-chip>
            </v-col>
            <v-col cols="1">
              <v-chip label color="#8ecae6">DEL. + SAVE</v-chip>
            </v-col>
          </v-row>
        </v-card>

        <transition-group name="fade">

          <v-card style="border-color: #fff;box-shadow: none; text-align:center" v-for="Sentence in searchedSentences" :key="Sentence.id">
            <v-row style="margin:0;padding:0">

              <v-col tile cols="2.5" class="d-flex pa-0 ma-1">
                <v-text-field :disabled="inputsDisabled" hide-details tile outlined autocomplete="off" v-model="Sentence.english" :value="Sentence.english" class="ma-0 pa-0 text-english"></v-text-field>
              </v-col>

              <v-col tile cols="2.5" class="d-flex pa-0 ma-1">
                <v-text-field :disabled="inputsDisabled" hide-details tile outlined autocomplete="off" v-model="Sentence.russian" :value="Sentence.russian" class="ma-0 pa-0"></v-text-field>
              </v-col>

              <v-col tile cols="1" class="d-flex pa-0 ma-1">
                <v-select :disabled="inputsDisabled" hide-details tile outlined autocomplete="off" :items="cases" v-model="Sentence.caseRequired" :class="getBackgroundColour(Sentence.caseRequired)" :value="Sentence.caseRequired" class="ma-0 pa-0"></v-select>
              </v-col>

              <v-col tile cols="1" class="d-flex pa-0 ma-1">
                <v-select :disabled="inputsDisabled" hide-details tile outlined autocomplete="off" :items="types" v-model="Sentence.type[0]" :value="Sentence.type[0]" class="ma-0 pa-0" :class="getColourClass(Sentence.type[0])"></v-select>
              </v-col>

              <v-col tile cols="2.5" class="d-flex pa-0 ma-1">
                <v-text-field :disabled="inputsDisabled" hide-details tile outlined autocomplete="off" v-model="Sentence.words" :value="Sentence.words" class="ma-0 pa-0"></v-text-field>
              </v-col>

              <v-col cols="1">
                <v-btn :disabled="inputsDisabled" icon color="#e63946" @click="deleteSentence(Sentence)">
                  <v-icon> mdi-delete </v-icon>
                </v-btn>

                <v-btn :disabled="inputsDisabled" icon color="#90be6d" @click="updateSentence(Sentence)">
                  <v-icon> mdi-check-circle </v-icon>
                </v-btn>
              </v-col>

            </v-row>
          </v-card>

        </transition-group>

      </div>


<!-- pagination -->
      <div v-if="Sentences.length > 0 && searchedSentences.length == 0">

        <v-card style="border-color: #fff;box-shadow: none; text-align:center">
          <v-row style="margin:0;padding:0">
            <v-col cols="2.5">
              <v-chip label color="#8ecae6">English</v-chip>
            </v-col>
            <v-col cols="2.5">
              <v-chip label color="#8ecae6">Russian</v-chip>
            </v-col>
            <v-col cols="1">
              <v-chip label color="#8ecae6">Case Required</v-chip>
            </v-col>
            <v-col cols="1">
              <v-chip label color="#8ecae6">Type</v-chip>
            </v-col>
            <v-col cols="2.5">
              <v-chip label color="#8ecae6">Adjectives</v-chip>
            </v-col>
            <v-col cols="1">
              <v-chip label color="#8ecae6">DEL. + SAVE</v-chip>
            </v-col>
          </v-row>
        </v-card>

        <transition-group name="fade">

          <v-card style="border-color: #fff;box-shadow: none; text-align:center" v-for="Sentence in Sentences" :key="Sentence.id">
            <v-row style="margin:0;padding:0">

              <v-col tile cols="2.5" class="d-flex pa-0 ma-1">
                <v-text-field :disabled="inputsDisabled" hide-details tile outlined autocomplete="off" v-model="Sentence.english" :value="Sentence.english" class="ma-0 pa-0 text-english"></v-text-field>
              </v-col>

              <v-col tile cols="2.5" class="d-flex pa-0 ma-1">
                <v-text-field :disabled="inputsDisabled" hide-details tile outlined autocomplete="off" v-model="Sentence.russian" :value="Sentence.russian" class="ma-0 pa-0"></v-text-field>
              </v-col>

              <v-col tile cols="1" class="d-flex pa-0 ma-1">
                <v-select :disabled="inputsDisabled" hide-details tile outlined autocomplete="off" :items="cases" v-model="Sentence.caseRequired" :class="getBackgroundColour(Sentence.caseRequired)" :value="Sentence.caseRequired" class="ma-0 pa-0"></v-select>
              </v-col>

              <v-col tile cols="1" class="d-flex pa-0 ma-1">
                <v-select :disabled="inputsDisabled" hide-details tile outlined autocomplete="off" :items="types" v-model="Sentence.type[0]" :value="Sentence.type[0]" class="ma-0 pa-0" :class="getColourClass(Sentence.type[0])"></v-select>
              </v-col>

              <v-col tile cols="2.5" class="d-flex pa-0 ma-1">
                <v-text-field :disabled="inputsDisabled" hide-details tile outlined autocomplete="off" v-model="Sentence.words" :value="Sentence.words" class="ma-0 pa-0"></v-text-field>
              </v-col>

              <v-col cols="1">
                <v-btn :disabled="inputsDisabled" icon color="#e63946" @click="deleteSentence(Sentence)">
                  <v-icon> mdi-delete </v-icon>
                </v-btn>

                <v-btn :disabled="inputsDisabled" icon color="#90be6d" @click="updateSentence(Sentence)">
                  <v-icon> mdi-check-circle </v-icon>
                </v-btn>
              </v-col>

            </v-row>
          </v-card>

        </transition-group>

        <template>
          <div class="text-center">
            <v-pagination
              color="#ffb703"
              v-model="page"
              :length="numberOfPages"
              @input="updatePagination()"
            ></v-pagination>
          </div>
        </template>

      </div>

    </div>
  </v-app>
</template>

<style media="screen">
  .v-application--wrap{
    min-height: 0;
  }

  h1{
    margin: 0 0 20px 0;
  }

  .viewWordsContent {
    width: calc(100vw - 40px);
    margin: auto;
    margin-top: 0;
    padding: 20px;
    text-align: left;
  }

  .text-english {
    background-color: #ffb703;
    font-weight: bold;
  }

  .colour-masculine {
    background-color: #a2d2ff !important;
    padding: 0;
    margin: 0;
  }

  .colour-feminine {
    background-color: #ffafcc !important;
    padding: 0;
    margin: 0;
  }

  .colour-neuter {
    background-color: #b5e48c !important;
    padding: 0;
    margin: 0;
  }

  .colour-nom {
    background-color: #403d39;
  }
  .colour-acc {
    background-color: #ffbe0b;
  }
  .colour-prep {
    background-color: #00b4d8;
  }
  .colour-gen {
    background-color: #e73265;
  }
  .colour-dat {
    background-color: #90be6d;
  }
  .colour-inst {
    background-color: #ada7c9;
  }

  .colour-plural {
    background-color: #383838;
  }

  .v-select__selection,
  .v-select__selection--comma,
  .v-select.v-text-field input {
    color: #fff !important;
    font-weight: bold;
  }
</style>










<script>
import {db} from '../firebase/db';
import firebase from 'firebase';

export default {
  title: "Sentences - Adjectives",
  data() {
    return {
      signedIn: false,
      page: 1,
      pageLimit: 10,
      numberOfPages: 1,
      words: [],
      wordsSelected: [],
      Field: [],
      searchedSentences: [],
      sentenceSearchBar: "",
      searchedResultTag: "results",
      stats: [],
      Sentences: [],
      SentencesTotal: [],
      newItem: "",
      docItems: ['englishTranslation', 'part1', 'part2', 'caseRequired', 'type', 'words'],
      cases: ['nom', 'gen', 'accI', 'accA', 'dat', 'inst', 'prep'],
      types: ['masc', 'fem', 'neut', 'plu'],
      sentenceRussianField: "",
      sentenceCaseRequiredField: "",
      sentenceTypeField: "",
      sentenceWordsField: [],
      sentenceEnglishField: "",
      inputsDisabled: false
    }
  },
  methods: {

    getColourClass(value) {

      if (value == 'masc') {
        return 'colour-masculine';
      } else if (value == 'fem') {
        return 'colour-feminine';
      } else if (value == 'neut') {
        return 'colour-neuter';
      } else if (value == 'plu') {
        return 'colour-plural';
      }

    },

    async search() {
      console.log(this.sentenceSearchBar);

      await db.collection("SentencesAdjectives").where("words", "array-contains", this.sentenceSearchBar)
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {

              var sentence = doc.data();
              sentence.id = doc.id;
              this.searchedSentences.push(sentence)

            })
          }
        })

        for (var i = this.searchedSentences.length - 1; i >= 0; i--) {
          if (! this.searchedSentences[i].words.includes(this.sentenceSearchBar)) {
              this.searchedSentences.splice(i, 1);
          }
        }

        console.log(this.searchedSentences);

        this.searchedResultTag = (this.searchedSentences.length == 1) ? "result" : "results"


    },



    async updatePagination() {

      this.Sentences = []



      var totalLength = (this.SentencesTotal.length % this.pageLimit == 1) ? this.SentencesTotal.length + 1 : this.SentencesTotal.length

      console.log(this.SentencesTotal.length % this.pageLimit);




      if ((this.SentencesTotal.length % this.pageLimit != 0) && this.page == this.numberOfPages) {

        var remainder = this.SentencesTotal.length % this.pageLimit
        this.Sentences = this.SentencesTotal.slice(Math.max(this.SentencesTotal.length - remainder, 0))


        console.log("LAST: " + this.Sentences);

      } else if ((this.pageLimit * this.page) <= totalLength) {

        console.log("HELLO!!!!!!!");

                  if (this.SentencesTotal[(this.pageLimit * (this.page - 1))] == "NULL") {

                    var indextemp = (this.pageLimit * (this.page - 1))

                    for (var i = 0; i < this.pageLimit; i++) {
                      await db.collection("SentencesAdjectives").orderBy("sentenceID").startAt(indextemp).limit(1)
                        .get()
                        .then((querySnapshot) => {
                          if (!querySnapshot.empty) {
                            querySnapshot.forEach((doc) => {

                              var sentence = doc.data();
                              sentence.id = doc.id
                              this.SentencesTotal[indextemp] = sentence

                            })
                          }
                        })

                        indextemp++
                    }

                  }

                  var arrayComponent1 = (this.pageLimit * (this.page - 1))
                  var counter1 = 0;

                  while (counter1 < this.pageLimit) {
                    if (this.SentencesTotal[arrayComponent1 + counter1] == null) {
                      break;
                    } else {
                      this.Sentences.push(this.SentencesTotal[arrayComponent1 + counter1])
                      counter1++
                    }
                  }

      } else {

        console.log("SentencesTotal length:  " + this.SentencesTotal.length);
        console.log((this.page - 1 * this.pageLimit));

        while (this.SentencesTotal.length < ((this.page - 1) * this.pageLimit)) {
          this.SentencesTotal.push("NULL")
        }

        console.log(this.SentencesTotal);

        var temp = ((this.page - 1) * this.pageLimit)

        await db.collection("SentencesAdjectives").orderBy("sentenceID").startAt(temp).limit(this.pageLimit)
          .get()
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              querySnapshot.forEach((doc) => {

                var sentence = doc.data();

                sentence.id = doc.id
                this.SentencesTotal.push(sentence)

              })
            }
          })



          var arrayComponent = ((this.page - 1) * this.pageLimit)
          var counter = 0;

          while (counter < this.pageLimit) {
            if (this.SentencesTotal[arrayComponent + counter] == null) {
              break;
            }
            this.Sentences.push(this.SentencesTotal[arrayComponent + counter])
            counter++
          }

      }


      console.log("Words length:  " + this.Sentences.length);
      console.log(this.Sentences);
      console.log("---");
      console.log("Words Total length:  " + this.SentencesTotal.length);
      console.log(this.SentencesTotal);


    },




    ensureWordsAreArray(){
      console.log(this.sentenceWordsField);

      //var wordsArray
      if (! Array.isArray(this.sentenceWordsField) && this.sentenceWordsField != null) {
        this.sentenceWordsField = this.sentenceWordsField.split(',');
      } else if (this.sentenceWordsField == null || this.sentenceWordsField == "") {
        this.sentenceWordsField = []
      } else {
        //wordsArray = this.sentenceWordsField;
      }

      console.log(this.sentenceWordsField);

    },



    toggleAllInputs() {
      this.inputsDisabled = !this.inputsDisabled;

      return this.inputsDisabled;
    },


    getBackgroundColour(caseAbbreviation) {
      var caseAbbreviations = ['nom', 'accA', 'accI', 'prep', 'gen', 'dat', 'inst']
      var colourClasses = ['colour-nom', 'colour-acc', 'colour-acc', 'colour-prep', 'colour-gen', 'colour-dat', 'colour-inst']

      return colourClasses[caseAbbreviations.indexOf(caseAbbreviation)]

    },


    //+-----------------------
    //| addSentence()
    //+-----------------------

    async addSentence() {

      this.toggleAllInputs();

      await this.ensureWordsAreArray();

      //+------------------
      //| Get stats
      //+------------------
      var stats = await db.collection('Statistics').doc('STATISTICS').get();
      stats = stats.data();
      var numberOfSentencesAdjectives = stats.numberOfSentencesAdjectives;
      console.log(stats);

      console.log("~~~~~~~~~~~");

      //+------------------
      //| If all fields are valid
      //+------------------
      if (this.sentenceRussianField != ""
          && this.sentenceEnglishTranslationField != "" && this.sentenceCaseRequiredField != ""
          && this.sentenceTypeField != "" && this.sentenceWordsField != "") {

            //+------------------
            //| Add sentence
            //+------------------
            await db.collection("SentencesAdjectives").add({
              russian: this.sentenceRussianField,
              english: this.sentenceEnglishField,
              caseRequired: this.sentenceCaseRequiredField,
              type: [this.sentenceTypeField],
              words: this.sentenceWordsField,
              sentenceID: numberOfSentencesAdjectives
            })

            numberOfSentencesAdjectives++;

            console.log("~~~~~~~~~~~");
            console.log("Added sentence");
            console.log("~~~~~~~~~~~");

            //+------------------
            //| Get doc id of sentence just added
            //+------------------

            var sentence, sentenceID;
            await db.collection("SentencesAdjectives").where("sentenceID", "==", (numberOfSentencesAdjectives - 1))
              .get()
              .then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    sentence = doc.data();
                    sentenceID = doc.id;
                  })
                })
            console.log(sentence);
            console.log("Sentence ID: " + sentenceID);
            console.log("~~~~~~~~~~~");

            // var newlyAddedAdjectives = 0;


            for (var i = 0; i < sentence.words.length; i++) {

              var wordExists = false;
              var word, wordID;
              await db.collection("Adjectives").where("english", "==", sentence.words[i]).limit(1)
                .get()
                .then((querySnapshot) => {
                  if (!querySnapshot.empty) {
                    querySnapshot.forEach((doc) => {
                      wordExists = true;
                      word = doc.data();
                      wordID = doc.id;
                    })
                  }
                })

              var caseReq = sentence.caseRequired.charAt(0).toUpperCase() + sentence.caseRequired.slice(1);
              var caseSentences = sentence.type + caseReq + "Sentences";

              if (!wordExists) {
                //+------------------
                //| Create incomplete word
                //|   add sentenceID to [case]Sentneces
                //|   increment newlyAddedWords
                //+------------------

                console.log("~~~~~~~~~~~");
                console.log("Creating new word: " + sentence.words[i]);
                console.log("~~~~~~~~~~~");

                await db.collection("Adjectives").add({
                  english: sentence.words[i],
                  incomplete: true,

                  softStem: false,

                  mascForms: ["", "", "", "", "", "", ""],
                  mascNomSentences: [],
                  mascGenSentences: [],
                  mascAccASentences: [],
                  mascAccISentences: [],
                  mascDatSentences: [],
                  mascInstSentences: [],
                  mascPrepSentences: [],

                  femForms: ["", "", "", "", "", "", ""],
                  femNomSentences: [],
                  femGenSentences: [],
                  femAccASentences: [],
                  femAccISentences: [],
                  femDatSentences: [],
                  femInstSentences: [],
                  femPrepSentences: [],

                  neutForms: ["", "", "", "", "", "", ""],
                  neutNomSentences: [],
                  neutGenSentences: [],
                  neutAccASentences: [],
                  neutAccISentences: [],
                  neutDatSentences: [],
                  neutInstSentences: [],
                  neutPrepSentences: [],

                  pluForms: ["", "", "", "", "", "", ""],
                  pluNomSentences: [],
                  pluGenSentences: [],
                  pluAccASentences: [],
                  pluAccISentences: [],
                  pluDatSentences: [],
                  pluInstSentences: [],
                  pluPrepSentences: [],

                  [caseSentences]: [sentenceID],
                });

              } else {
                //+------------------
                //| Word already exists
                //+------------------

                console.log("~~~~~~~~~~~");
                console.log("Amending existing word: " + sentence.words[i]);
                console.log("~~~~~~~~~~~");

                if (!word[caseSentences].includes(sentenceID)) {
                  word[caseSentences].push(sentenceID);
                }

                await db.collection("Adjectives").doc(wordID).update({
                  [caseSentences]: word[caseSentences],
                });


              }


            } //end for loop (for each word)

            //+------------------
            //| Update stats
            //+------------------
            await db.collection('Statistics').doc('STATISTICS').update({
              numberOfSentencesAdjectives: numberOfSentencesAdjectives
            })

            console.log("~~~~~~~~~~~");
            console.log("Stats updated");
            console.log("~~~~~~~~~~~");

            //+----------------------------
            //| Sets fields to blank.
            //+----------------------------
            this.sentenceRussianField = "";
            this.sentenceEnglishField = "";
            this.sentenceCaseRequiredField = "";
            this.sentenceTypeField = "";
            this.sentenceWordsField = [];
            this.$refs.englishTranslationRef.focus();





          } else {
            alert("Complete all fields")
          } //end of if (all entered fields valid)

          this.toggleAllInputs();


    },



    //+-----------------------
    //| updateSentence()
    //+-----------------------

    async updateSentence(Sentence) {

      this.toggleAllInputs();

      if (! Array.isArray(Sentence.words) && Sentence.words != null) {
        Sentence.words = Sentence.words.split(',');
      } else if (Sentence.words == null || Sentence.words == "") {
        Sentence.words = []
      } else {
        //wordsArray = this.sentenceWordsField;
      }

      console.log(Sentence);
      console.log(Sentence.id);


      if (Sentence.russian
          && Sentence.english != "" && Sentence.caseRequired != ""
          && Sentence.type != "" && Sentence.words != "") {


            // var stats = await db.collection('Statistics').doc('STATISTICS').get();
            // stats = stats.data();
            // var numberOfAdjectives = stats.numberOfAdjectives;


        //+------------------
        //| Delete
        //+------------------

        //Remove sentence from word's array -> eg. mascAccASentences
        var sentenceArrayName = Sentence.type + Sentence.caseRequired[0].toUpperCase() + Sentence.caseRequired.substring(1) + "Sentences"



        //Need to fetch the Sentence's words from the server, and then cycle through THAT array, to delete each, before re-adding with the Sentence.words list.

        var sentenceOG = await db.collection('SentencesAdjectives').doc(Sentence.id).get();
        sentenceOG = sentenceOG.data();
        // var sentenceOG = stats.numberOfAdjectives;

        for (var i = 0; i < sentenceOG.words.length; i++) {

          console.log(sentenceOG.words[i]);

          var word, wordID;
          var wordExistsDelete = false;
          await db.collection("Adjectives").where("english", "==", sentenceOG.words[i])
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  word = doc.data();
                  wordID = doc.id;
                  wordExistsDelete = true;
                })
              })

          if (wordExistsDelete) {
            var sentenceArray = word[sentenceArrayName];

            const index = sentenceArray.indexOf(Sentence.id);
            if (index > -1) {
              sentenceArray.splice(index, 1);
            }

            await db.collection('Adjectives').doc(wordID).update({
              [sentenceArrayName]: sentenceArray
            })
          }
        } // end of for each word

        await db.collection("SentencesAdjectives").doc(Sentence.id).delete()

        //+------------------
        //| Re-add
        //+------------------


        await db.collection("SentencesAdjectives").doc(Sentence.id).set({
          russian: Sentence.russian,
          english: Sentence.english,
          caseRequired: Sentence.caseRequired,
          type: [Sentence.type[0]],
          words: Sentence.words,
          sentenceID: Sentence.sentenceID
        })


        for (i = 0; i < Sentence.words.length; i++) {

          console.log(Sentence.words[i]);

          var wordExists = false;

          await db.collection("Adjectives").where("english", "==", Sentence.words[i]).limit(1)
            .get()
            .then((querySnapshot) => {
              if (!querySnapshot.empty) {
                querySnapshot.forEach((doc) => {
                  wordExists = true;
                  word = doc.data();
                  wordID = doc.id;
                })
              }
            })

          var caseReq = Sentence.caseRequired.charAt(0).toUpperCase() + Sentence.caseRequired.slice(1);
          var caseSentences = Sentence.type + caseReq + "Sentences";

          if (!wordExists) {
            //+------------------
            //| Create incomplete word
            //|   add sentenceID to [case]Sentneces
            //|   increment newlyAddedWords
            //+------------------

            console.log("~~~~~~~~~~~");
            console.log("Creating new word: " + Sentence.words[i]);
            console.log("~~~~~~~~~~~");

            await db.collection("Adjectives").add({
              english: Sentence.words[i],
              incomplete: true,

              softStem: false,

              mascForms: ["", "", "", "", "", "", ""],
              mascNomSentences: [],
              mascGenSentences: [],
              mascAccASentences: [],
              mascAccISentences: [],
              mascDatSentences: [],
              mascInstSentences: [],
              mascPrepSentences: [],

              femForms: ["", "", "", "", "", "", ""],
              femNomSentences: [],
              femGenSentences: [],
              femAccASentences: [],
              femAccISentences: [],
              femDatSentences: [],
              femInstSentences: [],
              femPrepSentences: [],

              neutForms: ["", "", "", "", "", "", ""],
              neutNomSentences: [],
              neutGenSentences: [],
              neutAccASentences: [],
              neutAccISentences: [],
              neutDatSentences: [],
              neutInstSentences: [],
              neutPrepSentences: [],

              pluForms: ["", "", "", "", "", "", ""],
              pluNomSentences: [],
              pluGenSentences: [],
              pluAccASentences: [],
              pluAccISentences: [],
              pluDatSentences: [],
              pluInstSentences: [],
              pluPrepSentences: [],

              [caseSentences]: [Sentence.id],
            });

          } else {
            //+------------------
            //| Word already exists
            //+------------------

            console.log("~~~~~~~~~~~");
            console.log("Amending existing word: " + Sentence.words[i]);
            console.log("~~~~~~~~~~~");

            if (!word[caseSentences].includes(Sentence.id)) {
              word[caseSentences].push(Sentence.id);
            }

            await db.collection("Adjectives").doc(wordID).update({
              [caseSentences]: word[caseSentences],
            });



          }



        } //end for loop (for each word)




      } else { //end if all fields valid
        alert("not valid")
      }

      this.toggleAllInputs();

    },



    //+-----------------------
    //| deleteSentence()
    //+-----------------------

    async deleteSentence(Sentence) {

      //+------------------
      //| Get stats
      //+------------------
      var stats = await db.collection('Statistics').doc('STATISTICS').get();
      stats = stats.data();
      var numberOfSentencesAdjectives = stats.numberOfSentencesAdjectives;

      //Cycle through words, delete Sentence.id

      console.log(Sentence.words);
      console.log(Sentence.id);
      console.log(Sentence.sentenceID);
      console.log(Sentence);

      //mascAccASentences
      var sentenceArrayName = Sentence.type + Sentence.caseRequired[0].toUpperCase() + Sentence.caseRequired.substring(1) + "Sentences"
      console.log(sentenceArrayName);

      for (var i = 0; i < Sentence.words.length; i++) {
        var word, wordID;
        await db.collection("Adjectives").where("english", "==", Sentence.words[i])
          .get()
          .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                word = doc.data();
                wordID = doc.id;
              })
            })

        console.log(word + " " + wordID);
        console.log(word[sentenceArrayName]);

        var sentenceArray = word[sentenceArrayName];


        const index = sentenceArray.indexOf(Sentence.id);
        if (index > -1) {
          sentenceArray.splice(index, 1);
        }


        await db.collection('Adjectives').doc(wordID).update({
          [sentenceArrayName]: sentenceArray
        })





      } // end of for each word





      //+------------------
      //| Delete the sentence, decrement sentence count
      //+------------------
      await db.collection("SentencesAdjectives").doc(Sentence.id).delete()
      numberOfSentencesAdjectives--;
      console.log("Decremented sentences, now: " + numberOfSentencesAdjectives);


      //+------------------
      //| To ensure no gaps in incremental id sequence, set sentence with 'last' id to the id of the sentence just deleted.
      //| (no need to do this if deleting the last added one)
      //+------------------
      if (Sentence.sentenceID != numberOfSentencesAdjectives) {
        console.log("NOT EQUAL");
        var sentenceIDtoChange;
        await db.collection("SentencesAdjectives").where("sentenceID", "==", numberOfSentencesAdjectives)
          .get()
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              querySnapshot.forEach((doc) => {
                console.log(wordID);
                sentenceIDtoChange = doc.id;
                })
              }
            })

        await db.collection('SentencesAdjectives').doc(sentenceIDtoChange).update({
          sentenceID: Sentence.sentenceID
        })
      }

      //+------------------
      //| Update stats
      //+------------------
      await db.collection('Statistics').doc('STATISTICS').update({
        numberOfSentencesAdjectives: numberOfSentencesAdjectives,
      })



    },

















    updateSentenceWords(){
      var temp = this.sentenceWordsField
      console.info('updated combo:', temp);

      this.wordsSelected.push(temp);
    },

    async checkIfAdmin() {
      var user = await db.collection("Users").doc(this.uid).get();
      user = user.data();
      if (!user.admin) {
        this.$router.push({ name: 'Index'})
      }
      // return user.admin;
    }




  },

  async beforeMount() {

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        this.uid = user.uid;
        this.signedIn = true;
        this.username = user.displayName;

        this.checkIfAdmin(user.uid);


      } else {
        // User is signed out
        // ...
        this.signedIn = false;
        this.$router.push({ name: 'Index'})
      }
    });


    this.stats = await db.collection("Statistics").doc("STATISTICS").get();
    this.stats = this.stats.data();


    this.numberOfPages = Math.ceil((this.stats.numberOfSentencesAdjectives) / this.pageLimit);


    await db.collection("SentencesAdjectives").orderBy("sentenceID").startAt(0).limit(this.pageLimit)
      .get()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {

            var sentence = doc.data();

            sentence.id = doc.id;

            this.Sentences.push(sentence)
            this.SentencesTotal.push(sentence)
            // var wordID = doc.id;

            console.log("TYPE: " + sentence.type);



          })
        }
      })





    console.log("~~~~");
    console.log("~~~~");
    console.log(this.Sentences);
    console.log("~~~~");
    console.log("~~~~");



  },

}
</script>










<!--  -->
